var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.user_settings")))]),_c('v-tabs',{staticStyle:{"border-bottom":"rgba(0, 0, 0, 0.1) solid thin"},attrs:{"icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticStyle:{"min-width":"160px"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.profile"))+" "),_c('v-icon',[_vm._v("mdi-account-group-outline")])],1),_c('v-tab',{staticStyle:{"min-width":"160px"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.admin.statistics.title"))+" "),_c('v-icon',[_vm._v("mdi-chart-areaspline-variant")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('UserSettings')],1),_c('v-tab-item',[_c('SpaceStatistics',{attrs:{"statsToDisplay":[
        'standard_subscription',
        'kiosk_subscription',
        'express_subscription',
        'active_proposition',
        'active_worker',
        'associated_worker',
        'worker_user',
        'order',
        'order_fully_honored',
        'order_partially_honored',
        'order_non_honored',
        'order_quantity',
        'sms_job_proposition',
        'accepted_job_proposition',
        'order_job_taken',
        'dpae_confirmation',
        'availability_request',
        'non_interpreted_answer',
        'availability_request_qualification',
        'availability_request_link_qualification',
        'message_sent',
        'message_received',
        'availability_history',
        'worker_in_contract',
        'applicant',
        'applicant_jobboard',
        'application',
        'application_jobboard']}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }