
import Vue from "vue"
import UserSettings from "@/views/account/UserSettings.vue"
import SpaceStatistics from "@/components/SpaceStatistic.vue"

export default Vue.extend({
    components: {
        UserSettings,
        SpaceStatistics
    },
    data () {
      return {
        tab: null
      }
    },
})
